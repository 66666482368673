export const removeNullsFromObject = (obj: any): any => {
  if (obj === null) {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj
      .map((item) => removeNullsFromObject(item))
      .filter((item) => item !== undefined);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const filteredValue = removeNullsFromObject(value);
      if (filteredValue !== undefined) {
        acc[key] = filteredValue;
      }
      return acc;
    }, {} as any);
  }

  return obj;
};
